/* eslint-disable no-console */
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, CircularProgress } from '@mui/material';
import { format } from 'date-fns';

import usePagedAdverts from '@/hooks/adverts/usePagedAdverts';
import useMetaCreativeBulk from '@/hooks/meta/useMetaCreativeBulk';
import useTikTokAdVideoBulk from '@/hooks/tiktok/useTikTokAdVideoBulk';
import { PagesAdvertItemModel } from '@/models/Adverts';

import Loading from '../utility/Loading';
import CampaignStatus from '../utility/statuses/CampaignStatus';

const CampaignsSocialMedia = ({ limit, active, type }: { limit?: number; active?: boolean; type?: string }) => {
  const navigate = useNavigate();

  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [adverts, setAdverts] = useState<PagesAdvertItemModel[]>([]);
  const [loadMoreOffset, setLoadMoreOffset] = useState<number>(0);
  const [totalAds, setTotalAds] = useState<number>(0);

  const { pagedAdverts, pagedAdvertsIsLoading, pagedAdvertsTotalCount } = usePagedAdverts({
    status: active ? 'active' : '',
    offset: loadMoreOffset,
    limit: limit ?? 10,
  });

  const metaAdIds = useMemo(
    () => pagedAdverts?.filter((advert) => advert.platform === 'meta').map((advert) => advert.id),
    [pagedAdverts]
  );

  const tiktokAdsData = useMemo(
    () =>
      pagedAdverts?.filter(
        (advert) =>
          advert.platform === 'tiktok' &&
          advert.metadata.advertiserId &&
          advert.metadata.identityId &&
          advert.metadata.tikTokItemId
      ),
    [pagedAdverts]
  );

  const { metaCreativeBulk, metaCreativeBulkIsLoading } = useMetaCreativeBulk({ advertIds: metaAdIds });
  const { tikTokAdVideoBulk, tikTokAdVideoBulkIsLoading } = useTikTokAdVideoBulk({ ads: tiktokAdsData });

  const pagedAdvertsWithImages = useMemo(() => {
    if (!pagedAdverts || !metaAdIds || !tiktokAdsData || !metaCreativeBulk || !tikTokAdVideoBulk) return undefined;
    if (!metaAdIds?.length && !tiktokAdsData?.length) return pagedAdverts;

    return pagedAdverts.map((advert) => {
      if (advert.platform === 'meta') {
        return {
          ...advert,
          image:
            metaCreativeBulk?.find((item) => item.advertId === advert.id)?.data?.image_url ??
            metaCreativeBulk?.find((item) => item.advertId === advert.id)?.data?.video_url ??
            '/images/logos/social-media-icon.svg',
        };
      } else if (advert.platform === 'tiktok') {
        return {
          ...advert,
          image:
            tikTokAdVideoBulk?.find((item) => item?.advertId === advert.id)?.data?.video_info.poster_url ??
            '/images/logos/social-media-icon.svg',
        };
      }
      return advert;
    });
  }, [pagedAdverts, metaAdIds, tiktokAdsData, metaCreativeBulk, tikTokAdVideoBulk]);

  useEffect(() => {
    if (!pagedAdvertsWithImages) return;

    setAdverts((value) => {
      const advertIds = value?.map((item) => item.id);

      const pagedAdvertsWithImagesToAdd = pagedAdvertsWithImages.filter((item) => !advertIds?.includes(item.id));
      if (!pagedAdvertsWithImagesToAdd.length) return value;

      return value ? [...value, ...pagedAdvertsWithImagesToAdd] : pagedAdvertsWithImagesToAdd;
    });
    setIsInitialLoad(false);
  }, [adverts, metaAdIds, metaCreativeBulk, pagedAdverts, pagedAdvertsWithImages, tikTokAdVideoBulk]);

  const isMoreLoading = useMemo(
    () => pagedAdvertsIsLoading || metaCreativeBulkIsLoading || tikTokAdVideoBulkIsLoading,
    [metaCreativeBulkIsLoading, pagedAdvertsIsLoading, tikTokAdVideoBulkIsLoading]
  );

  useEffect(() => {
    if (!pagedAdvertsTotalCount) return;

    setTotalAds(pagedAdvertsTotalCount);
  }, [pagedAdvertsTotalCount]);

  const handleClick = (item: PagesAdvertItemModel) => {
    navigate(`/campaigns/${item.platform}/${item.id}`);
  };

  return (
    <>
      {isInitialLoad && type === 'homepage-card' && (
        <div className={`centered-loading mt120`}>
          <Loading size={'small'} />
        </div>
      )}
      {isInitialLoad && type !== 'homepage-card' && (
        <div className={`centered-loading`}>
          <Loading />
        </div>
      )}

      {!isInitialLoad && adverts && adverts.length === 0 && (
        <div className="text-center card mt16">
          <h4 data-testid="no-pitches-available">No social media campaigns yet</h4>
        </div>
      )}
      {!isInitialLoad &&
        adverts &&
        adverts.length > 0 &&
        adverts?.map((item, index) => (
          <div
            key={index}
            className="card-inner w100p mb8 p16 list-item-parent"
            style={{ '--animation-number': `${index}` } as React.CSSProperties}
            onClick={() => handleClick(item)}
          >
            <div className="d-flex list-item campaign flex-wrap">
              <img src={item.image || '/images/logos/social-media-icon.svg'} alt="campaign-image" />
              <div className="mt-auto mb-auto text-left min-w120">
                <div>
                  <CampaignStatus status={item.status} />
                </div>
                <p className="text-faded small capitalize">{item.summary.goal.replace('-', ' ')}</p>
              </div>
              <div className="flex-grow mt-auto mb-auto">
                <div className="fs-16 fw-bold">{item.name}</div>
                <p className="text-faded small">
                  {format(new Date(item.summary.startTime), 'dd/MM/yy')}
                  {' - '}
                  {format(new Date(item.summary.endTime), 'dd/MM/yy')}
                </p>
              </div>
              <div className="mt-auto mb-auto ml-auto">
                {item.summary?.platforms?.map((item: string, index: number) => (
                  <img key={index} className="social-icon pl8" src={`/images/logos/${item}-logo-white.svg`} alt="" />
                ))}
              </div>
            </div>
          </div>
        ))}
      {adverts && adverts.length > 0 && totalAds > adverts.length && !isInitialLoad && (
        <div className="text-center mt16">
          <Button onClick={() => setLoadMoreOffset(adverts.length)}>
            {loadMoreOffset && isMoreLoading ? <CircularProgress size={16} /> : 'Load more'}
          </Button>
        </div>
      )}
    </>
  );
};

export default CampaignsSocialMedia;
