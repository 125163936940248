import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, CircularProgress } from '@mui/material';
import { format } from 'date-fns';

import useAccountPlaylistPitch from '@/hooks/playlist/useAccountPlaylistPitches';
import { PlaylistPitchAccountItemsModel } from '@/models/Playlist';

import Loading from '../utility/Loading';
import CampaignStatus from '../utility/statuses/CampaignStatus';

const CampaignsPlaylists = () => {
  const navigate = useNavigate();

  const [pitches, setPitches] = useState<PlaylistPitchAccountItemsModel[]>([]);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [loadMoreToken, setLoadMoreToken] = useState<string>();
  const [continuationToken, setContinuationToken] = useState<string>();

  const handleClick = (item: PlaylistPitchAccountItemsModel) => {
    navigate(`/campaigns/playlist-pitch/${item.id}`);
  };

  const { accountPlaylistPitch, accountPlaylistPitchIsLoading } = useAccountPlaylistPitch({
    continuationToken: loadMoreToken,
  });

  useEffect(() => {
    if (!accountPlaylistPitch) return;

    if (accountPlaylistPitch.continuationToken) {
      setContinuationToken(encodeURIComponent(accountPlaylistPitch.continuationToken));
    } else {
      setContinuationToken('');
    }
    setPitches((value) => [...value, ...accountPlaylistPitch.items]);
    setIsInitialLoad(false);
  }, [accountPlaylistPitch, loadMoreToken]);

  return (
    <>
      {isInitialLoad && (
        <div className="centered-loading">
          <Loading />
        </div>
      )}
      {!isInitialLoad && pitches && pitches.length === 0 && (
        <div className="text-center card mt16">
          <h4 data-testid="no-pitches-available">No playlist pitches yet</h4>
        </div>
      )}
      {!isInitialLoad &&
        pitches.length > 0 &&
        pitches?.map((item, index) => (
          <div
            onClick={() => {
              handleClick(item);
            }}
            key={item.id}
            className="card-inner w100p mb8 p16 list-item-parent"
            style={{ '--animation-number': `${index}` } as React.CSSProperties}
          >
            <div className="d-flex list-item campaign flex-wrap">
              <img src={item.track?.image ? item.track?.image : '/images/logos/unhurd-logo.png'} alt="to-do-img" />
              <div className="mt-auto mb-auto text-center">
                <div>
                  <CampaignStatus status={item.status} />
                </div>
                <p className="text-faded small">{format(new Date(item.createdAt), 'dd/MM/yy')}</p>
              </div>
              <div className="flex-grow mt-auto mb-auto">
                <div className="fs-16 fw-bold text-left">{item.name}</div>
                <p className="text-faded small">{item.artist.name}</p>
              </div>
              <p className="text-faded small mt-auto mb-auto ml-auto">{`Submitted to ${
                item.playlists.length
              } ${item.playlists.length === 1 ? 'playlist' : 'playlists'}`}</p>
            </div>
          </div>
        ))}
      {continuationToken !== '' && !isInitialLoad && (
        <div className="text-center mt16">
          <Button onClick={() => setLoadMoreToken(continuationToken)}>
            {loadMoreToken && accountPlaylistPitchIsLoading ? <CircularProgress size={16} /> : 'Load more'}
          </Button>
        </div>
      )}
    </>
  );
};

export default CampaignsPlaylists;
