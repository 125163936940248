import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Dialog, DialogContent, DialogTitle, Icon, Tooltip } from '@mui/material';

import { PlaylistPitchAccountItemsPlaylistModel } from '@/models/Playlist';

import ProgressBar from '../utility/ProgressBar';
import PlaylistPitchStatus from '../utility/statuses/PlaylistPitchStatus';

const PlaylistPitchModal = ({
  open,
  output,
  playlist,
  trackName,
  artistName,
}: {
  open: boolean;
  output: () => void;
  playlist: PlaylistPitchAccountItemsPlaylistModel;
  trackName?: string;
  artistName?: string;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const showSliderFeedback = () => {
    if (playlist?.status === 2) {
      return true;
    }
    if (
      playlist?.status === 1 &&
      playlist.feedback.moodMatch === 0 &&
      playlist.feedback.productionQuality === 0 &&
      playlist.feedback.trackAppeal === 0
    ) {
      return false;
    }
    if (
      playlist.feedback.moodMatch !== null ||
      playlist.feedback.productionQuality !== null ||
      playlist.feedback.trackAppeal !== null
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className="w90p">
      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason === 'backdropClick') {
            output();
          }
        }}
      >
        <Button className="icon-btn pos-abs r0 mr20" onClick={() => output()}>
          <Icon>close</Icon>
        </Button>
        <DialogTitle>
          <div className="d-flex gap8">
            <h3>{trackName}</h3>
          </div>
          <a
            className="fs-custom d-flex text-white"
            style={{ '--customFontSize': '0.8rem' } as CSSProperties}
            href={playlist.url}
            target="_blank"
            rel="noreferrer"
          >
            <img className="mt2" src="/images/logos/spotify-logo.svg" alt="spotify" height={16} />
            <span className="pl4 pr4">{playlist.name}</span>
            <Icon
              className="mt2 fs-custom text-faded"
              style={{ '--customFontSize': '1rem' } as CSSProperties}
              onClick={() => {
                window.open(playlist?.url);
              }}
            >
              open_in_new
            </Icon>
          </a>
        </DialogTitle>
        {playlist && (
          <DialogContent className="pb0">
            <PlaylistPitchStatus status={playlist?.status || 0} placedOn={playlist?.placedOn} />

            {playlist && playlist?.status !== 0 && (
              <div>
                {playlist?.feedback?.feedback && (
                  <div className="mt20">
                    <h4 className="mt10">{t('PLAYLISTING.CURATOR-FEEDBACK')}</h4>
                    <p className="text-faded mt10 mb10">{playlist?.feedback?.feedback}</p>
                  </div>
                )}

                {showSliderFeedback() && (
                  <>
                    <div className="d-flex jc-space-between mt20">
                      <p>{t('PLAYLISTING.PRODUCTION-QUALITY')}</p>
                      <Tooltip title={t('PLAYLISTING.PRODUCTION-QUALITY-DESCRIPTION')}>
                        <Icon className="material-symbols-outlined text-faded">info</Icon>
                      </Tooltip>
                    </div>

                    <ProgressBar progress={(100 / 5) * playlist?.feedback?.productionQuality || 5} />
                    <div className="d-flex jc-space-between">
                      <p className="small text-faded">Low</p>
                      <p className="small text-faded">High</p>
                    </div>
                    <div className="d-flex jc-space-between mt20">
                      <p>{t('PLAYLISTING.TRACK-APPEAL')}</p>
                      <Tooltip title={t('PLAYLISTING.TRACK-APPEAL-DESCRIPTION')}>
                        <Icon className="material-symbols-outlined text-faded">info</Icon>
                      </Tooltip>
                    </div>
                    <ProgressBar progress={(100 / 5) * playlist?.feedback?.trackAppeal || 5} />
                    <div className="d-flex jc-space-between">
                      <p className="small text-faded">Low</p>
                      <p className="small text-faded">High</p>
                    </div>
                    <div className="d-flex jc-space-between mt20">
                      <p>{t('PLAYLISTING.PLAYLIST-MOOD-MATCH')}</p>
                      <Tooltip title={t('PLAYLISTING.PLAYLIST-MOOD-MATCH-DESCRIPTION')}>
                        <Icon className="material-symbols-outlined text-faded">info</Icon>
                      </Tooltip>
                    </div>
                    <ProgressBar progress={(100 / 5) * playlist?.feedback?.moodMatch || 5} />
                    <div className="d-flex jc-space-between">
                      <p className="small text-faded">Low</p>
                      <p className="small text-faded">High</p>
                    </div>
                  </>
                )}
              </div>
            )}
            {playlist && playlist?.status === 0 && (
              <div>
                <p className="text-faded mt10">{t('PLAYLISTING.PENDING-MESSAGE-PART-1')} </p>
                <p className="text-faded mt20 mb10">{t('PLAYLISTING.PENDING-MESSAGE-PART-2')}</p>
              </div>
            )}
            {playlist.status !== 0 && (
              <div className="mt32">
                <h4>{t('PLAYLISTING.A-MESSAGE-FROM-UNHURD')}</h4>
                {playlist.status === 1 && (
                  <p className="mt8 text-faded">
                    {t('PLAYLISTING.SUCCESS-MESSAGE')}{' '}
                    <span
                      className="text-white underline cursor-pointer"
                      onClick={() => {
                        navigate('/promote?promoteState=playlisting');
                      }}
                    >
                      {t('PLAYLISTING.SUCCESS-MESSAGE-LINK-TEXT')}
                    </span>{' '}
                    {t('PLAYLISTING.SUCCESS-MESSAGE-PART-2')}
                  </p>
                )}
                {playlist.status === 2 && (
                  <p className="mt8 text-faded">
                    {t('PLAYLISTING.UNSUCCESSFUL-MESSAGE')}{' '}
                    <span
                      className="text-white underline cursor-pointer"
                      onClick={() => {
                        window.open(
                          `mailto:hello@unhurd.co.uk?subject=Unsuccessful Playlist Pitch - ${trackName} by ${artistName} - ${playlist.name}`
                        );
                      }}
                    >
                      {t('PLAYLISTING.UNSUCCESSFUL-MESSAGE-LINK-TEXT')}
                    </span>{' '}
                    {t('PLAYLISTING.UNSUCCESSFUL-MESSAGE-PART-2')}
                  </p>
                )}
              </div>
            )}
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
};

export default PlaylistPitchModal;
