import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';
import { format } from 'date-fns';

import useSubscription from '@/hooks/account/useSubscription';
import useAccountContext from '@/hooks/context/useAccountContext';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import useWebsite from '@/hooks/website-builder/useWebsite';
import useWebsiteMailingList from '@/hooks/website-builder/useWebsiteMailingList';
import WebsiteBuilderAPI from '@/network/WebsiteBuilderAPI';
import { handleApiError } from '@/utility/api';

import Loading from '../utility/Loading';
import CustomIcons from '../utility/microcomponents/CustomIcons';
import DialogModal from '../utility/modals/DialogModal';
import CampaignStatus from '../utility/statuses/CampaignStatus';

const CampaignsFanHub = () => {
  const navigate = useNavigate();

  const { accountId } = useAccountContext();
  const { dispatchSnackbar } = useSnackbarContext();

  const { isSubscribed } = useSubscription();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const { website, websiteIsLoading, refetchWebsite, websiteError } = useWebsite();

  const [noWebsite, setNoWebsite] = useState<boolean>(false);

  const { websiteMailingList } = useWebsiteMailingList({ mailingListId: website?.accountId });

  const exportMailingList = useCallback(() => {
    if (!websiteMailingList) return;

    const url = window.URL.createObjectURL(new Blob([websiteMailingList], { type: 'application/zip' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `mailing-list.zip`);
    document.body.appendChild(link);
    link.click();
  }, [websiteMailingList]);

  const deleteFanHub = useCallback(async () => {
    try {
      if (!accountId) return;

      await WebsiteBuilderAPI.deleteFanHub({ accountId });
    } catch (error: unknown) {
      handleApiError({ error, customMessage: 'Failed to delete fan hub', dispatchSnackbar });
    } finally {
      await refetchWebsite();
      setDeleteModalOpen(false);
    }
  }, [accountId, dispatchSnackbar, refetchWebsite]);

  const handleClick = () => {
    if (isSubscribed) {
      navigate(`/promote?promoteState=website`);
    } else {
      dispatchSnackbar({
        type: 'OPEN_SNACKBAR',
        payload: {
          type: 'error',
          message: 'You need to be subscribed to access this feature',
        },
      });
    }
  };

  useEffect(() => {
    if (websiteError) {
      setNoWebsite(true);
    }
  }, [websiteError]);

  return (
    <>
      {websiteIsLoading && (
        <div className="centered-loading">
          <Loading />
        </div>
      )}
      <DialogModal
        open={deleteModalOpen}
        output={(confirm) => {
          if (confirm) {
            deleteFanHub();
          }
          setDeleteModalOpen(false);
        }}
        isDelete={true}
        title="Delete Fan Hub"
        content="Are you sure you want to delete your fan hub? This cannot be undone. You will lose access to your mailing list information after deleting so we recommend downloading it first."
      />
      {!websiteIsLoading && website && !websiteError && (
        <div
          className="card-inner w100p mb8 p16 list-item-parent"
          style={{ '--animation-number': 0 } as React.CSSProperties}
        >
          <div className="d-flex list-item campaign flex-wrap">
            <img
              src={website.profileImageUrl ? website.profileImageUrl : '/images/logos/unhurd-logo.png'}
              alt="to-do-img"
            />
            <div className="mt-auto mb-auto text-center">
              <div>
                <CampaignStatus status={website.activationStatus?.activationStatus} />
              </div>
              {website.createdAt && (
                <p className="text-faded small">{format(new Date(website.createdAt), 'dd/MM/yy')}</p>
              )}
            </div>
            <div className="flex-grow mt-auto mb-auto">
              <div className="fs-16 fw-bold text-left">{website.artistName}</div>
              <p
                className="small underline text-faded cursor-pointer"
                onClick={() => {
                  window.open(website.fullRoute, '_blank');
                }}
              >
                {website.fullRoute}
              </p>
            </div>
            <div className="mt-auto mb-auto ml-auto">
              <Button className="btn-white" onClick={exportMailingList}>
                Export mailing list
              </Button>
            </div>
            <div
              className="mt-auto mb-auto ml-auto"
              onClick={() => {
                handleClick();
              }}
            >
              <CustomIcons className="pt8" name="edit" />
            </div>
            <div
              className="mt-auto mb-auto ml-auto"
              onClick={() => {
                setDeleteModalOpen(true);
              }}
            >
              <CustomIcons className="pt4 material-symbols-outlined text-error" name="delete" />
            </div>
          </div>
        </div>
      )}

      {!websiteIsLoading && (!website || noWebsite) && (
        <div className="text-center card mt16">
          <h4 data-testid="no-pitches-available">No Fan Hub set up</h4>
        </div>
      )}
    </>
  );
};

export default CampaignsFanHub;
